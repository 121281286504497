import { createScript, hydrated } from "@sciam/shared";

import { client as adsConfig } from "./ads";
import { client as gtmConfig } from "./gtm";
import { client as onetrustConfig } from "./onetrust";
import { client as onetrustBetaConfig } from "./onetrust-beta";
import { client as pianoConfig } from "./piano";
import { client as idpConfig } from "./idp";
import { client as chartbeatConfig, clientMab as chartbeatMabConfig } from "./chartbeat";
import customEvents from "./custom";

export async function load() {
  const loadOneTrust = window.location.search.match(/debug=tcfv2/)
    ? createScript(onetrustBetaConfig)
    : createScript(onetrustConfig);
  const loadPianoScript = createScript(pianoConfig);
  const loadGtm = createScript(gtmConfig);
  const loadSciAds = createScript(adsConfig);
  const loadIDP = createScript(idpConfig);

  // Load OneTrust, Piano, Ads in parallel
  const onetrust = loadOneTrust();
  const piano = loadPianoScript();
  const sciads = loadSciAds();
  const idp = loadIDP();

  // Wait for hydration before loading GTM to prioritize performance
  await Promise.allSettled([onetrust.loaded, hydrated()]);

  const gtm = loadGtm();

  // Load items that require consent. Nothing else will wait for them.
  window.consentQueue.push(({ consent, isFirstRun }) => {
    // Load Chartbeat if we have consent and it's the callback's first run
    if (!isFirstRun || !consent.performance) return;

    const chartbeat = createScript(chartbeatConfig)();
    const chartbeatMab = createScript(chartbeatMabConfig)();
  });

  customEvents();

  // Wait for all scripts' `onload` events to fire
  await Promise.allSettled([onetrust.loaded, gtm.loaded, piano.loaded, sciads.loaded, idp.loaded]);

  console.log("[loader] all scripts loaded");

  // Wait for all scripts' `readyWhen` conditions to be met
  await Promise.allSettled([onetrust.ready, gtm.ready, piano.ready, sciads.ready, idp.ready]);

  console.log("[loader] all scripts ready");

  // Generate a report of all scripts' loaded and ready states
  // This is only intended for debugging purposes
  window.scriptReport = await generateScriptReport({
    onetrust,
    gtm,
    piano,
    sciads,
    idp,
  });

  return window.scriptReport;
}

/**
 * Generate a report of all scripts' loaded and ready states
 *
 * Though this returns a promise, it'll resolve immediately because all the promises it awaits are already resolved
 * @returns {Promise<{ [scriptName: string]: { loaded: boolean, ready: boolean }>}}
 */
async function generateScriptReport(scripts = {}) {
  const scriptNames = Object.keys(scripts);

  return await Promise.allSettled(
    // Generate an array of Promises that resolve to an object with the script's loaded and ready state
    scriptNames.map(async (scriptName) => {
      let value = scripts[scriptName];
      return {
        loaded: await value.loaded.then(() => true).catch(() => false),
        ready: await value.ready.then(() => true).catch(() => false),
      };
    }),
    // Transform the result into an object with the script name as the key
  ).then((results) => {
    return results.reduce((acc, result, index) => {
      acc[scriptNames[index]] = result.value;
      return acc;
    }, {});
  });
}
